<template>
  <v-content class="fullscreen-div" fluid>
    <div v-if="isLoading">
       <v-container class="hidden-sm-and-down container-desktop ">
      <SkeletonWeb />
    </v-container>
    <v-container class="hidden-md-and-up content-mobile">
      <SkeletonMobile />
    </v-container>
    </div>
    <div else v-if="jobId.length !== 0">
      <v-container grid-list-xl class="container-desktop">
        <v-layout row wrap color="#F5F5F5">
          <v-col cols="12" sm="12" md="12" lg="12" class="hidden-md-and-up">
            <div>
              <a @click="$router.go(-1)">
                <v-row>
                  <v-icon color="#002855" class="mx-4 mt-n12">mdi-chevron-left</v-icon>
                  <p class="btn-back mt-n9">Volver</p>
                </v-row>
              </a>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" class="hidden-sm-and-down">
            <div>
              <a @click="$router.go(-1)">
                <v-row>
                  <v-icon color="#002855" class="mx-4 mt-n3">mdi-chevron-left</v-icon>
                  <p class="btn-back">Volver</p>
                </v-row>
              </a>
            </div>
          </v-col>
          <DetailWeb :similarOffers="similarOffers" :jobId="jobId" @getJobs="getJobs" />
          <DetailMobile :jobId="jobId" />
        </v-layout>
      </v-container>
    </div>
  </v-content>
</template>
<script>
import { getJobById, getAllJobsByCategory } from '../../../services/dynamo/postulants';
import DetailWeb from './DetailWeb.vue';
import DetailMobile from './DetailMobile.vue';
import SkeletonWeb from './SkeletonWeb.vue';
import SkeletonMobile from './SkeletonMobile.vue';

export default {
  name: 'JobDetailPage',
  components: {
    SkeletonWeb,
    SkeletonMobile,
    DetailWeb,
    DetailMobile,
  },
  data() {
    return {
      isLoading: true,
      similarOffers: [],
      jobId: [],
      idOfferSelect: '',
    };
  },
  watch: {
    $route(to, _from) {
      console.log('to', to);
      console.log('_from', _from);
    },
  },
  async created() {
    await this.getJobs(this.$route.params.id);
  },
  computed: {
    id() {
      return this.idJob;
    },
  },
  methods: {
    async getJobs(idOfferSelect) {
      try {
        this.isLoading = true;
        const jobData = await this.fetchJobData(idOfferSelect);
        if (!jobData) {
          this.redirectToNotFoundPage();
          return;
        }
        this.jobId = jobData;
        const jobsBySameCategory = await this.fetchJobsByCategory(jobData.category, idOfferSelect);
        this.similarOffers = jobsBySameCategory;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchJobData(id) {
      const response = await getJobById(id);
      return response?.data;
    },

    async fetchJobsByCategory(category, excludeId) {
      const response = await getAllJobsByCategory(category);
      const jobs = response?.data;
      return jobs?.filter(job => job.id !== excludeId);
    },

    redirectToNotFoundPage() {
      this.$router.replace({ name: 'NotFoundPage' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.fullscreen-div {
  height: 100vh;
  background-color: $secondary-white !important;
}
.btn-back {
  color: $secondary-blue;
  font-size: 16px;
  font-weight: 500;
  font-family: $principal-font !important;
}
</style>
