<template>
  <v-content style="margin-left: 5px; margin-right: 5px" fluid no-gutters>
    <div class="content-mobile mr-6 ml-6 mt-n16">
      <v-row class="no-gutters content-center">
        <v-col>
          <v-skeleton-loader
            type="image"
            width="282px"
            height="20px"
            class="ma-1"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="no-gutters mt-2">
        <v-col>
          <div class="content-center">
            <v-skeleton-loader
              type="image"
              width="150px"
              height="20px"
              class="ma-1"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>

      <v-row class="no-gutters mt-2">
        <v-row>
          <v-col class="content-center">
            <v-skeleton-loader
              type="image"
              width="90px"
              height="20px"
              class="ma-1"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-row>

      <div class="ml-2 mr-2 my-9">
        <v-row>
          <v-row class="no-gutters ml-4">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-skeleton-loader
                type="image"
                width="70px"
                height="20px"
                class="ma-1"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-skeleton-loader
                type="image"
                width="70px"
                height="20px"
                class="ma-1"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col cols="12" sm="6" md="4" lg="3">
              <div>
                <v-skeleton-loader
                  type="image"
                  width="70px"
                  height="20px"
                  class="ma-1"
                ></v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>

      <v-row class="content-mobile">
        <v-col>
          <v-skeleton-loader type="article">
          </v-skeleton-loader>
            <v-skeleton-loader type="article">
          </v-skeleton-loader>
           <v-skeleton-loader type="article">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <div class="navbar">
        <v-row>
          <v-col cols="6">
            <div class="text-center ml-16">
              <v-skeleton-loader
                type="image"
                width="105px"
                height="28px"
                class="ma-4 pa-1"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col cols="6" class="mt-2">
            <div class="my-0 mr-8 text-center">
              <v-skeleton-loader
                type="image"
                width="40px"
                height="28px"
                class="ma-2 pa-1"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-content>
</template>
<script>
export default {
  name: 'SkeletonMobile',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar {
  z-index: 100;
  background-color: $principal-white;
  bottom: 0;
  position: fixed;
  left: 0;
  width: 100%;
}
.navbar a {
  float: left;
  display: block;
  color: $secondary-white-dark;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}
.navbar a:hover {
  background: $secondary-gray-light;
  color: $principal-black;
}
.content-mobile {
  background: $secondary-white !important;
}
.container-menu {
  margin-bottom: 36px;
  height: 28px;
}
.my-bottom-navigation {
  height: 100%;
}
</style>
