<template>
  <v-container class="hidden-sm-and-down container-desktop ma-4 pa-4 mt-n1">
    <div>
      <v-row>
        <v-col :cols="4" :sm="4" :xs="4">
          <v-row>
            <v-col>
              <v-card width="390" height="160" style="background-color: rgba(0, 150, 143, 0.1)"
                class="card-select mb-3">
                <div v-if="jobId !== 0">
                  <v-card-title class="custom-title">
                    <v-row class="justify-space-between pa-1 ma-1 mt-1 ml-2" no-gutters justify="center">
                      <v-col :cols="6" :sm="6" :xs="6">
                        <div class="title-card-select word-break mb-3">
                          {{ `${cutString(jobId.title)}` }}
                        </div>
                      </v-col>
                      <v-col :cols="4" :sm="4" :xs="4">
                        <div class="date-card-select">
                          {{ daysAgo === 0 ? "Hoy" : `Hace ${daysAgo} días` }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-subtitle class="content-title ml-2">
                    <v-row class="justify-space-between" no-gutters justify="center">
                      <v-col :cols="8" :sm="8" :xs="8">
                        <div class="ma-1 mt-n4">
                          <p class="text-category">
                            {{ jobId.category }}
                          </p>
                        </div>
                      </v-col>
                      <v-col :cols="4" :sm="4" :xs="4">
                        <div class="mt-n4">
                          <v-chip color="#FFFFFF">
                            <p class="modality-card-select mt-4">
                              {{ jobId.modality }}
                            </p>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                    <div>
                      <v-row>
                        <v-col :cols="6" :sm="8" :xs="8" :lg="6">
                          <v-row>
                            <v-col cols="2">
                              <v-icon color="#CE0F69">mdi-map-marker</v-icon>
                            </v-col>
                            <v-col cols="9">
                              <p class="location-card-select">
                                {{ jobId.sede }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-subtitle>
                </div>
              </v-card>

              <div v-if="similarOffers.length !== 0" class="mt-8">
                <p class="text-other pa-1 mb-6">Otros puestos similares</p>
                <v-card width="390" height="160" v-for="(offer, i) in similarOffers" :key="i" class="mb-2 card-others"
                  @click="jobDetail(offer)">
                  <div>
                    <v-card-title class="custom-title">
                      <v-row class="justify-space-between pa-1 ma-1 mt-1 ml-2" no-gutters justify="center">
                        <v-col :cols="6" :sm="6" :xs="6">
                          <div class="title-card-select word-break mb-3">
                            {{ `${cutString(offer.title)}` }}
                          </div>

                        </v-col>
                        <v-col :cols="4" :sm="4" :xs="4">
                          <div class="date-card-select">
                            {{ formatDaysAgo(offer.createdDate) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle class="content-title ml-2">
                      <v-row class="justify-space-between" no-gutters justify="center">
                        <v-col :cols="8" :sm="8" :xs="8">
                          <div class="ma-1 mt-n4">
                            <p class="text-category">
                              {{ offer.category }}
                            </p>
                          </div>
                        </v-col>
                        <v-col :cols="4" :sm="4" :xs="4">
                          <div class="mt-n7">
                            <v-chip color="#FFFFFF">
                              <p class="modality-card-select mt-4">
                                {{ offer.modality }}
                              </p>
                            </v-chip>
                          </div>
                        </v-col>
                      </v-row>
                      <div>
                        <v-row>
                          <v-col :cols="6" :sm="8" :xs="8" :lg="6">
                            <v-row>
                              <v-col cols="2">
                                <v-icon color="#CE0F69">mdi-map-marker</v-icon>
                              </v-col>
                              <v-col cols="9">
                                <p class="location-card-select">
                                  {{ offer.sede }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-subtitle>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="12" :sm="8" :xs="8">
          <v-row>
            <v-col>
              <v-card width="1160px" height="738px" class="card-detail">
                <div>
                  <v-card-title>
                    <v-row class="justify-space-between">
                      <v-col :cols="6" :sm="6" :xs="6">
                        <v-col>
                          <div class="card-title mt-0 word-break">
                            {{ jobId.title }}
                          </div>
                        </v-col>
                        <v-col>
                          <div class="text-category mt-n6">
                            {{ jobId.category }}
                          </div>
                        </v-col>
                        <div class="ml-2 mt-n2">
                          <v-col>
                            <v-row>
                              <v-icon color="#CE0F69">mdi-map-marker</v-icon>

                              <p class="location-card-select my-2">
                                {{ jobId.sede }}
                              </p>
                            </v-row>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col v-if="$vuetify.breakpoint.width <= 1900" :cols="6" :sm="6" :xs="12">
                        <!-- Contenido para pantalla hasta 1900px -->
                        <v-row class="content-postula">
                          <v-col cols="8" class="d-flex justify-start" xl="6">
                            <v-btn @click="applyFromJob()" class="rounded-span" color="#CE0F69" rounded>
                              <p class="btn-apply mt-3 text-center">
                                Postularme
                              </p>
                            </v-btn>
                          </v-col>
                          <v-col cols="3" xl="5" class="d-flex justify-end">
                            <div>
                              <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left"
                                :direction="'bottom'" :open-on-hover="hover" :transition="transition" class="mt-0">
                                <template v-slot:activator>
                                  <v-btn v-model="fab" fab dark class="mt-4 btn-share">
                                    <v-icon v-if="fab" color="#CE0F69">
                                      mdi-close
                                    </v-icon>
                                    <v-img v-else color="#CE0F69" left
                                      src="../../../assets/newlanding/Jobs/icon-add.svg" class="ma-4"></v-img>
                                  </v-btn>
                                </template>
                                <div>
                                  <v-col v-for="(option, index) in options" :key="index">
                                    <v-speed-dial-item :direction="'down'">
                                      <v-row align="center">
                                        <v-col>
                                          <v-btn :key="option.id" :color="option.color" @click="
                                            share(option.url, option.id)
                                            " fab small>
                                            <v-icon :color="option.color" class="white--text">{{ option.icono
                                              }}</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-speed-dial-item>
                                  </v-col>
                                </div>
                              </v-speed-dial>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col v-else :cols="6" :sm="6" :xs="6" :xl="4">
                        <!-- Contenido para pantalla mayor a 1024px -->
                        <v-row class="content-postula">
                          <v-col cols="6" class="d-flex justify-start" xl="8">
                            <v-btn @click="applyFromJob()" class="rounded-span" color="#CE0F69" rounded>
                              <p class="btn-apply mt-3 text-center">
                                Postularme
                              </p>
                            </v-btn>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end">
                            <div>
                              <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left"
                                :direction="'bottom'" :open-on-hover="hover" :transition="transition" class="mt-0">
                                <template v-slot:activator>
                                  <v-btn v-model="fab" fab dark class="mt-4 btn-share">
                                    <v-icon v-if="fab" color="#CE0F69">
                                      mdi-close
                                    </v-icon>
                                    <v-img v-else color="#CE0F69" left
                                      src="../../../assets/newlanding/Jobs/icon-add.svg" class="ma-4"></v-img>
                                  </v-btn>
                                </template>
                                <div>
                                  <v-col v-for="(option, index) in options" :key="index">
                                    <v-speed-dial-item :direction="'down'">
                                      <v-row align="center">
                                        <v-col>
                                          <v-btn :key="option.id" :color="option.color" @click="
                                            share(
                                              option.url,
                                              option.id,
                                              option.tag
                                            )
                                            " fab small>
                                            <v-icon :color="option.color" class="white--text">{{ option.icono
                                              }}</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-speed-dial-item>
                                  </v-col>
                                </div>
                              </v-speed-dial>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-snackbar v-model="snackbar" :timeout="3000">
                        Copiado
                        <template v-slot:actions>
                          <v-btn color="#012754" variant="tonal" @click="snackbar = false">
                            Close
                          </v-btn>
                        </template>
                      </v-snackbar>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-divider></v-divider>
                    <div class="pa-4 mt-5 container-tag">
                      <v-row>
                        <v-row class="no-gutters">
                          <v-col :cols="6" :sm="4" :xs="6">
                            <v-chip color="#F9FAFB">
                              <v-img left src="../../../assets/newlanding/Jobs/icon-time.svg" class="ma-1"></v-img>
                              <p class="text-icon mt-3">
                                {{ jobId.modality }}
                              </p>
                            </v-chip>
                          </v-col>
                          <v-col :cols="6" :sm="4" :xs="6">
                            <v-chip color="#F9FAFB">
                              <v-img left src="../../../assets/newlanding/Jobs/icon-modalidad.svg" class="ma-1"></v-img>
                              <p class="text-icon mt-3">
                                {{ jobId.typeWork }}
                              </p>
                            </v-chip>
                          </v-col>
                          <v-col cols="3">
                            <div v-if="jobId.disability == 'Si'">
                              <v-chip color="#F9FAFB">
                                <v-img left src="../../../assets/newlanding/Jobs/icon-apto.svg" class="ma-1"></v-img>
                              </v-chip>
                            </div>
                          </v-col>
                        </v-row>
                      </v-row>
                    </div>
                  </v-card-text>
                  <section :class="`scrollable-card
                    mt-1 ${jobId.title.length > 80 ? 'scrollable-card-1' : 'scrollable-card-2'}`"
                    :style="{ maxHeight: sectionMaxHeight }">
                    <div>
                      <v-row class="no-gutters mb-0 mt-0 mr-6">
                        <v-col cols="12" sm="6" md="12" lg="12" class="ml-5 no-padding" text-justify>
                          <div v-html="jobId.content" class="pa-4 card-intro"></div>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row no-gutters>
                      <div v-if="jobId.disability == 'Si'" class="ml-6">
                        <v-row>
                          <v-col cols="12" sm="12" md="12" class="text-apto">
                            <v-chip color="#fff">
                              <v-img src="../../../assets/newlanding/Jobs/icon-check.svg" width="18px" height="18px"
                                class="mt-n3"></v-img>
                              <p class="text-content ml-2">
                                Apto para personas con discapacidad
                              </p>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>
                  </section>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailWeb',
  props: {
    idJob: {
      type: String,
      required: false,
    },
    job: {
      type: Object,
      required: false,
    },
    similarOffers: {
      type: Array,
      required: false,
    },
    jobId: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      sectionMaxHeight: '700px',
      snackbar: false,
      daysAgo: 0,
      options: [],
      direction: 'down',
      fab: false,
      hover: false,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      linkMedia: '',
    };
  },
  created() {
    this.daysAgo = this.calculateDaysAgo(this.jobId.createdDate)
    this.linkMedia = encodeURI(`${window.location.href}`);
    this.jobSelect = this.jobId.category;
    this.nameJobSelect = this.jobId.title;
    this.shareMediaSocial();
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },

  methods: {
    formatDaysAgo(date) {
      const daysAgo = this.calculateDaysAgo(date);
      return daysAgo === 0 ? "Hoy" : `Hace ${daysAgo} días`;
    },
    cutString(inputString) {
      const characters = /[-./]/g;
      // eslint-disable-next-line quotes
      const charactersWithSpaces = inputString?.replace(characters, (match) => `${match} `);
      return charactersWithSpaces;
    },
    jobDetail(offer) {
      this.$emit('getJobs', offer.id);
      this.$router.replace({
        path: `/empleos/${offer.id}`,
      });
    },
    applyFromJob() {
      sessionStorage.setItem('jobCategory', this.jobId.category);
      sessionStorage.setItem('jobId', this.jobId.id);
      sessionStorage.setItem('createdBy', this.jobId.createdBy);
      sessionStorage.setItem('title', this.jobId.title);
      sessionStorage.setItem('testLink', this.jobId.testLink);
      sessionStorage.setItem('igc', this.jobId.igc);
      this.$gtag.event('LPB_Btn_Postularme', {
        event_category: 'LPB_Btn_Postularme',
        event_label: 'LPB_Btn_Postularme',
        value: 1,
      });
      this.$router.push({ name: 'selection', params: { igc: this.jobId.igc } });
    },
    shareMediaSocial() {
      this.options = [
        {
          id: 1,
          texto: 'Facebook',
          icono: 'mdi-facebook',
          color: '#002855',
          url: `https://www.facebook.com/share.php?u=${encodeURIComponent(
            this.linkMedia,
          )}`,
          tag: 'LPB_btn_Facebook',
        },
        {
          id: 2,
          texto: 'Twitter',
          icono: 'mdi-twitter',
          color: '#4298B5',
          url: `http://twitter.com/share?&url=${encodeURIComponent(
            this.linkMedia,
          )}&text=${this.nameJobSelect
            }&title=Te comparto los avisos de Konecta:`,
          tag: 'LPB_btn_Twitter',
        },
        {
          id: 3,
          texto: 'Whatssapp',
          icono: 'mdi-whatsapp',
          color: '#2AD34A',
          url: `https://api.whatsapp.com/send?text=Te comparto los avisos de Konecta: ${encodeURIComponent(
            this.linkMedia,
          )}`,
          tag: 'LPB_btn_Whatsapp',
        },
        {
          id: 4,
          texto: 'Copiar',
          icono: 'mdi-content-copy',
          color: '#CE0F69',
          url: '',
          tag: 'LPB_btn_Link',
        },
      ];
    },
    async copyText() {
      await navigator.clipboard.writeText(this.linkMedia);
      this.snackbar = true;
    },
    async share(url, id, tag) {
      this.$gtag.event(tag, {
        event_category: tag,
        event_label: tag,
        value: 1,
      });
      if (id === 4) {
        await this.copyText();
      } else {
        const newTab = window.open(url, '_blank');
        newTab.focus();
      }
    },
    calculateDaysAgo(date) {
      const createdDay = moment(date).startOf('day');
      const currentDay = moment().startOf('day');
      const difference = currentDay.diff(createdDay, 'days');
      return difference;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.container-tag {
  display: flex;
  justify-content: flex-start;
}

.container-tag>* {
  margin-right: 1px;
}

.content-postula {
  margin-top: 16px;
}

.word-break {
  word-break: break-word;
}

.btn-share {
  background-color: $step-pink-dark !important;
  margin-bottom: 12px;
  width: 50px;
  height: 50px;
}

.content-title {
  margin-bottom: -20px;
}

.custom-title {
  margin-bottom: -10px;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.no-scroll-margin {
  margin: 0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: $principal-white;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary-blackLight;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $principal-white;
}

::-webkit-scrollbar-thumb:active {
  background-color: $principal-white;
}

.rounded-span {
  width: 180.14px;
  height: 51.39px !important;
}

.scrollable-card {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-detail {
  border-radius: 24px;
  box-shadow: 4px 4px 8px $step-black-light;
}

.card-title {
  color: $principal-blue-black;
  font-size: 20px;
  font-weight: 700;
  font-family: $principal-font !important;
  line-height: 24px;
}

.text-other {
  color: $secondary-black;
  font-size: 19px;
  font-weight: 540;
  font-family: $principal-font !important;
  line-height: 19px;
}

.text-category {
  color: $principal-blue-black;
  font-size: 16px;
  font-weight: 400;
  font-family: $principal-font !important;
}

.location-card-select {
  color: $secondary-black;
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 20px;
}

.modality-card-select {
  color: $secondary-black;
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 20px;
}

.date-card-select {
  color: $secondary-fucsia;
  font-size: 13px;
  font-weight: 500;
  font-family: $principal-font !important;
  line-height: 20px;
}

.card-select {
  border-radius: 12px;
  box-shadow: 4px 4px 8px $step-black-light;
}

.card-others {
  border-radius: 12px;
  box-shadow: 4px 4px 8px $step-black-light;
}

$font-size: 19px;
$line-height: 1.2;
$lines-to-show: 2;

.title-card-select {
  color: $secondary-black;
  font-weight: 700;
  font-family: $principal-font !important;
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 350px;
  height: $font-size*$line-height*$lines-to-show;
  /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-desktop {
  background: $secondary-white;
}

.container-menu {
  margin-bottom: 36px;
  height: 28px;
}

.subtitle-job {
  color: $principal-blue-black;
  font-size: 16px;
  font-weight: 500;
  font-family: $principal-font !important;
  line-height: 19px;
}

.text-icon {
  color: $principal-blue-black;
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 20px;
}

.btn-apply {
  text-transform: none;
  color: $principal-white;
  font-size: 16px;
  font-weight: 700;
  font-family: $principal-font !important;
  line-height: 22px;
}

.text-apto {
  color: $secondary-black;
  font-size: 16px;
  font-weight: 400;
  font-family: $principal-font !important;
}

.text-content {
  color: $secondary-black;
  font-size: 16px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 0px;
}

.card-intro {
  color: $secondary-black;
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 20px;
}

.text-map {
  color: $secondary-black;
  font-size: 12px;
  font-weight: 401;
  font-family: $principal-font !important;
  line-height: 20px;
}

.flex-column-responsive {
  flex-direction: row;
}

@media (min-width: 2000px) {
  .scrollable-card {
    height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1700px) {
  .flex-column-responsive {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1263px) {
  $font-size: 14px;
  $line-height: 1.2;
  $lines-to-show: 2;

  .title-card-select {
    color: $secondary-black;
    font-weight: 700;
    font-family: $principal-font !important;
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 350px;
    height: $font-size*$line-height*$lines-to-show;
    /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-category {
    font-size: 15px;
  }

  .modality-card-select {
    font-size: 13px;
  }

  .text-other {
    font-size: 17px;
  }

  .card-title {
    font-size: 17px;
  }

  .rounded-span {
    border-radius: 30px;
    width: 145px;
    height: 49px !important;
  }

  .btn-apply {
    font-size: 16px;
    font-weight: 550;
  }
}

@media (max-width: 1024px) {
  .title-card-select {
    font-size: 14px;
  }

  .text-category {
    font-size: 15px;
  }

  .modality-card-select {
    font-size: 13px;
  }

  .text-other {
    font-size: 17px;
  }

  .rounded-span {
    border-radius: 30px;
    width: 140px;
    height: 30px;
  }

  .btn-apply {
    font-size: 14px;
    font-weight: 550;
  }

  .card-title {
    font-size: 17px;
  }

  .btn-share {
    background-color: $step-pink-dark !important;
    margin-bottom: 2px;
  }

  .btn-action {
    text-transform: none;
    color: $principal-white;
    font-size: 12px;
    line-height: 22px;
  }
}

@media (min-width: 1024px) {
  .scrollable-card-1 {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .scrollable-card-2 {
    height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
