<template>
  <v-container class="container-desktop ma-4 pa-4">
    <div>
      <v-row>
        <v-col :cols="4" :sm="4" :xs="4">
          <v-row>
            <v-col>
              <v-card
                width="390"
                height="160"
                style="#FFFFFF"
                class="card-select mb-3"
              >
                <v-card-title class="custom-title">
                  <v-row
                    class="justify-space-between mt-0 ml-2"
                    no-gutters
                    justify="center"
                  >
                    <v-col :cols="6" :sm="6" :xs="6">
                      <v-skeleton-loader type="article"></v-skeleton-loader>
                    </v-col>
                    <v-col :cols="4" :sm="4" :xs="4">
                      <v-skeleton-loader type="article"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <div class="mt-5">
                <div class="pa-1">
                  <v-skeleton-loader></v-skeleton-loader>
                </div>
                <v-card
                  width="390"
                  height="160"
                  class="mb-4 card-select"
                  v-for="(off, i) in 3"
                  :key="i"
                >
                  <div>
                    <v-card-title class="custom-title">
                      <v-row
                        class="justify-space-between mt-0 ml-3"
                        no-gutters
                        justify="center"
                      >
                        <v-col :cols="6" :sm="6" :xs="6">
                          <v-skeleton-loader type="article"></v-skeleton-loader>
                        </v-col>
                        <v-col :cols="4" :sm="5" :xs="4">
                          <div class="date-card-select ml-2">
                            <v-skeleton-loader
                              type="article"
                            ></v-skeleton-loader>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="12" :sm="8" :xs="8">
          <v-row>
            <v-col>
              <v-card width="1100px" height="700px" class="card-detail">
                <div>
                  <v-card-title>
                    <v-row class="justify-space-between">
                      <v-col :cols="6" :sm="8" :xs="6">
                        <v-col>
                          <div class="mt-0">
                            <v-skeleton-loader
                              type="article"
                            ></v-skeleton-loader>
                          </div>
                        </v-col>
                        <v-card-text>
                          <div class="pa-5 mt-0">
                            <v-row>
                              <v-row class="no-gutters">
                                <v-col :cols="6" :sm="4" :xs="6">
                                  <v-skeleton-loader
                                    type="image"
                                    width="90px"
                                    height="20px"
                                    class="ma-1"
                                  ></v-skeleton-loader>
                                </v-col>
                                <v-col :cols="6" :sm="4" :xs="6">
                                  <v-skeleton-loader
                                    type="image"
                                    width="90px"
                                    height="20px"
                                    class="ma-1"
                                  ></v-skeleton-loader>
                                </v-col>
                                <v-col cols="3">
                                  <div>
                                    <v-skeleton-loader
                                      type="image"
                                      width="90px"
                                      height="20px"
                                      class="ma-1"
                                    ></v-skeleton-loader>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-row>
                          </div>
                        </v-card-text>
                        <v-col :cols="6" :sm="8" :xs="6">
                          <div class="mt-n6">
                            <v-skeleton-loader
                              type="image"
                              width="480px"
                              height="100px"
                            ></v-skeleton-loader>
                          </div>
                        </v-col>

                        <div class="ml-2 mt-n2">
                          <v-col :cols="6" :sm="8" :xs="6">
                            <v-row>
                              <v-skeleton-loader
                                type="image"
                                width="150px"
                                height="10px"
                                class="mt-2 ma-1"
                              ></v-skeleton-loader>
                              <p class="my-2">
                                <v-skeleton-loader
                                  type="image"
                                  width="480px"
                                  height="120px"
                                ></v-skeleton-loader>
                              </p>
                            </v-row>
                          </v-col>
                        </div>
                        <div class="ml-2 mt-n2">
                          <v-col :cols="6" :sm="8" :xs="6">
                            <v-row>
                              <v-skeleton-loader
                                type="image"
                                width="250px"
                                height="10px"
                                class="mt-2 ma-1"
                              ></v-skeleton-loader>
                              <p class="my-2">
                                <v-skeleton-loader
                                  type="image"
                                  width="480px"
                                  height="120px"
                                ></v-skeleton-loader>
                              </p>
                            </v-row>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col :cols="6" :sm="4" :xs="6">
                        <v-row class="flex-column-responsive" align="center">
                          <v-col cols="auto">
                           <v-skeleton-loader
                                type="card"
                                width="140px"
                                height="40px"
                                class="mt-4 ma-1"
                              ></v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'SkeletonWeb',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "./_app.scss";
.card-select {
  border-radius: 12px;
  box-shadow: 4px 4px 8px $step-black-light;
}
.card-detail {
  border-radius: 24px;
  box-shadow: 4px 4px 8px $step-black-light;
}
.custom-title {
  margin-bottom: -10px;
}
.card-content {
  background-color: $principal-white;
  border-radius: 12px;
}
.container-desktop {
  background: $secondary-white;
}
@media (max-width: 1024px) {
  .rounded-span {
    border-radius: 30px;
    width: 110px;
    height: 45px;
  }
}
</style>
