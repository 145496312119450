<!-- eslint-disable vue/no-parsing-error -->
<template>
  <v-container
    class="hidden-md-and-up content-mobile"
    style="margin-left: 5px; margin-right: 5px"
    fluid
    no-gutters
  >
    <div class="mr-6 ml-6 content-mobile">
      <v-row class="no-gutters text-center">
        <v-col>
          <p class="title-job mt-n8">{{ jobId.title }}</p>
        </v-col>
      </v-row>

      <v-row class="no-gutters mt-n3 text-center">
        <v-col>
          <p class="subtitle-job text-center">{{ jobId.category }}</p>
        </v-col>
      </v-row>

      <v-row class="no-gutters">
        <v-row>
          <v-col class="text-center">
            <v-chip color="#f9fafb">
              <v-icon color="#CE0F69">mdi-map-marker</v-icon>
              <p class="text-map mt-3">{{ jobId.sede }}</p>
            </v-chip>
          </v-col>
        </v-row>
      </v-row>

      <div class="container-tag my-9">
        <v-row>
          <v-row class="no-gutters">
            <v-col class="text-center">
              <v-chip color="white">
                <v-img
                  left
                  src="../../../assets/newlanding/Jobs/icon-time.svg"
                  class="ma-1"
                  width="14px"
                  height="14px"
                ></v-img>
                <p class="text-icon">{{ jobId.modality }}</p>
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col class="text-center">
              <v-chip color="white">
                <v-img
                  left
                  src="../../../assets/newlanding/Jobs/icon-modalidad.svg"
                  class="ma-1"
                  width="14px"
                  height="16px"
                ></v-img>
                <p class="text-icon">{{ jobId.typeWork }}</p>
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col class="text-center">
              <div v-if="jobId.disability == 'Si'">
                <v-chip color="white">
                  <v-img
                    left
                    src="../../../assets/newlanding/Jobs/icon-apto.svg"
                    class="ma-1"
                    width="14px"
                    height="16px"
                  ></v-img>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>

      <v-row class="content-mobile mb-0">
        <v-col>
          <div v-html="jobId.content"></div>
        </v-col>
      </v-row>

      <div>
        <v-row class="mb-16 ml-2">
          <div v-if="jobId.disability == 'Si'" class="ml-0">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-apto">
                <v-row>
                  <v-img
                    src="../../../assets/newlanding/Jobs/icon-check.svg"
                    class="mt-4"
                    aspect-ratio="1"
                    max-width="18px"
                    max-height="18px"
                  ></v-img>
                  <p class="text-content ml-2">
                    Apto para personas con discapacidad
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </div>
      <br />

      <div class="navbar">
        <v-row justify="center" class="mt-1">
          <v-col cols="12" md="6">
            <v-row justify="center">
              <v-btn
                rounded
                color="#CE0F69"
                class="text-center btn-postula"
                max-height="38px"
                max-width="140px"
                @click="applyFromJob()"
              >
                <p class="btn-action my-2 text-center">Postularme</p>
              </v-btn>
              <v-speed-dial
                v-model="fab"
                :top="top"
                :bottom="bottom"
                :right="right"
                :left="left"
                :direction="'top'"
                :open-on-hover="hover"
                :transition="transition"
                class="mt-2 text-center custom-button"
              >
                <template v-slot:activator>
                  <v-btn
                    v-model="fab"
                    fab
                    dark
                    class="mt-4 btn-share custom-button"
                    max-height="34px"
                    max-width="34px"
                  >
                    <v-icon v-if="fab" color="#CE0F69" class="ma-4">
                      mdi-close
                    </v-icon>
                    <v-img
                      v-else
                      color="#CE0F69"
                      left
                      src="../../../assets/newlanding/Jobs/icon-add.svg"
                      class="ma-4"
                      max-height="14px"
                      max-width="14px"
                    ></v-img>
                  </v-btn>
                </template>
                <div class="my-4">
                  <v-btn
                    v-for="(option, index) in options"
                    :key="index"
                    :color="option.color"
                    @click="share(option.url, option.id)"
                    fab
                    small
                    class="btn-social"
                  >
                    <v-icon :color="option.color" class="white--text">{{
                      option.icono
                    }}</v-icon>
                  </v-btn>
                </div>
              </v-speed-dial>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-snackbar v-model="snackbar" :timeout="3000">
        Copiado
        <template v-slot:actions>
          <v-btn color="#012754" variant="tonal" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'DetailMobile',
  props: {
    idJob: {
      type: String,
      required: false,
    },
    jobId: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      snackbar: false,
      options: [],
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      linkMedia: '',
      transition: 'slide-y-reverse-transition',
    };
  },
  created() {
    this.linkMedia = encodeURI(`${window.location.href}`);
    this.nameJobSelect = this.jobId.title;
    this.shareMediaSocial();
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  methods: {
    applyFromJob() {
      try {
        sessionStorage.setItem('jobCategory', this.jobId.category);
        sessionStorage.setItem('createdBy', this.jobId.createdBy);
        sessionStorage.setItem('title', this.jobId.title);
        sessionStorage.setItem('jobId', this.jobId.id);
        sessionStorage.setItem('testLink', this.jobId.testLink);
        this.$gtag.event('LPB_Btn_Postularme', {
          event_category: 'LPB_Btn_Postularme',
          event_label: 'LPB_Btn_Postularme',
          value: 1,
        });
        this.$router.push({ name: 'selection', params: { igc: this.jobId.igc } });
      } catch (error) {
        console.log(error);
      }
    },
    shareMediaSocial() {
      this.options = [
        {
          id: 1,
          texto: 'Facebook',
          icono: 'mdi-facebook',
          color: '#002855',
          url: `https://www.facebook.com/share.php?u=${encodeURIComponent(
            this.linkMedia,
          )}`,
        },
        {
          id: 2,
          texto: 'Twitter',
          icono: 'mdi-twitter',
          color: '#4298B5',
          url: `http://twitter.com/share?&url=${encodeURIComponent(
            this.linkMedia,
          )}&text=${
            this.nameJobSelect
          }&title=Te comparto los avisos de Konecta:`,
        },
        {
          id: 3,
          texto: 'Whatssapp',
          icono: 'mdi-whatsapp',
          color: '#2AD34A',
          url: `https://api.whatsapp.com/send?text=Te comparto los avisos de Konecta: ${encodeURIComponent(
            this.linkMedia,
          )}`,
        },
        {
          id: 4,
          texto: 'Copiar',
          icono: 'mdi-content-copy',
          color: '#CE0F69',
          url: '',
        },
      ];
    },
    async copyText() {
      await navigator.clipboard.writeText(this.linkMedia);
      this.snackbar = true;
    },
    async share(url, id) {
      if (id === 4) {
        await this.copyText();
      } else {
        const newTab = window.open(url, '_blank');
        newTab.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.custom-button {
  margin: 10px;
   margin-top: 16px !important;
 }
.container {
  padding: 20px;
}
@media (max-width: 960px) {
  .container {
    padding: 20px;
  }
}
.container-tag {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-apto {
  color: $secondary-black;
  font-size: 16px;
  font-weight: 400;
  font-family: $principal-font !important;
}
.btn-social {
  z-index: 200;
}
.btn-share {
  background-color: $step-pink-dark !important;
}
.navbar {
  position: fixed;
  z-index: 1;
  background-color: $principal-white;
  bottom: 0;
  left: 0;
  width: 100%;
}
.navbar a {
  float: left;
  display: block;
  color: $secondary-white-dark;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 17px;
}
.navbar a:hover {
  background: $secondary-gray-light;
  color: $principal-black;
}
.content-mobile {
  background: $secondary-white !important;
}
.title-job {
  color: $secondary-blue;
  font-size: 20px;
  font-weight: 800;
  font-family: $principal-font !important;
  line-height: 24px;
}
.subtitle-job {
  color: $secondary-blue;
  font-size: 16px;
  font-weight: 500;
  font-family: $principal-font !important;
  line-height: 19px;
}
.text-map {
  color: $secondary-black;
  font-size: 12px;
  font-weight: 401;
  font-family: $principal-font !important;
  line-height: 20px;
}
.text-icon {
  color: $secondary-blue;
  font-size: 12px;
  font-weight: 402;
  font-family: $principal-font !important;
  margin-top: 14px !important;
}
.text-content-intro {
  color: $secondary-black;
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 20px;
}
.text-offer {
  color: $secondary-blue;
  font-size: 18px;
  font-weight: 580;
  font-family: $principal-font !important;
  line-height: 20px;
}
.text-content {
  color: $secondary-black;
  font-size: 15px;
  font-weight: 400;
  font-family: $principal-font !important;
  margin-top: 12px;
}
.container-menu {
  margin-bottom: 36px;
  height: 28px;
}
.my-bottom-navigation {
  height: 100%;
}
.btn-postula {
  width: 160px;
  height: 60px;
  border-radius: 20px;
  margin: 10px;
  margin-top: 16px !important;
  margin-bottom: 8px;
}
.btn-action {
  text-transform: none;
  color: $principal-white;
  font-size: 14px;
  font-weight: 550;
  font-family: $principal-font !important;
  line-height: 22px;
}
.circle-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $step-pink-dark;
}
@media (max-width: 393px) {
  .text-icon {
    font-size: 11px;
    font-weight: 402;
    margin-top: 14px !important;
  }
}

</style>
